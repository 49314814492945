import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { ref } from 'vue';
export default (type) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'customerName',
      value: '',
      placeholder: '请输入客户名称',
    },
    {
      type: 'input',
      prop: 'contactsName',
      value: '',
      placeholder: '请输入联系人',
    },
    {
      type: 'multiSelect',
      prop: 'source',
      value: [],
      options: 'event_source',
      width: 180,
      placeholder: '请选择来源',
    },
    {
      type: 'multiSelect',
      prop: 'status',
      value: type === 2 ? [1, 2] : [],
      options: 'leads_status',
      width: 180,
      placeholder: '请选择状态',
    },
    {
      type: 'time',
      prop: ['startTime', 'endTime'],
      value: [],
      placeholder: ['创建开始时间', '创建结束时间'],
      isHidden: type === 3,
    },
    {
      type: 'time',
      prop: ['startDate', 'endDate'],
      value: [],
      placeholder: ['更新开始时间', '更新结束时间'],
      isHidden: type === 3,
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '客户名称',
      prop: 'customerName',
      minWidth: 120,
    },
    {
      label: '联系人',
      prop: 'contactsName',
      width: type === 2 ? 200 : 140,
    },
    {
      label: '联系电话',
      prop: 'contactsTelephone',
      width: type === 2 ? 200 : 130,
    },
    {
      label: '来源',
      prop: 'source',
      propDesc: 'sourceName', // 需要转换的列值的属性名
      width: type === 2 ? 200 : 100,
    },
    {
      label: '状态',
      prop: 'status',
      propDesc: 'statusName', // 需要转换的列值的属性名
      width: type === 2 ? 200 : 100,
    },

    {
      label: '负责人',
      prop: 'userNameList',
      width: 125,
      isHidden: type === 2, // 线索池不显示
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      width: 180,
    },
    {
      label: '更新时间',
      prop: 'lastEditTime',
      width: 180,
    },
    {
      label: '操作人',
      prop: 'lastOperator',
      width: 120,
      isHidden: type === 2, // 线索池不显示
    },
    {
      label: '操作时间',
      prop: 'updateTime',
      width: 180,
      isHidden: type === 2, // 线索池不显示
    },
  ];
  return { filterOptions, columnList, tableRef };
};
